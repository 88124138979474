import Vue from "vue";
import Vuetify from "vuetify/lib";
// import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    // breakpoint: {
    //     thresholds: {
    //         xs: 340,
    //         sm: 540,
    //         md: 800,
    //         lg: 1280,
    //     },
    //     scrollBarWidth: 24,
    // },
    rtl: true,
    theme: {
        themes: {
            light: {
                primary: '#E2001A',
                secondary: '#ffffff',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
});
