
import dashboardSection from "../../components/dashboardSection/index.vue";
import mapSection from "../../components/mapSection/index.vue";

mapSection
export default {
  name: "homePage",
  components: {
    dashboardSection,
    mapSection
  },
  methods: {
  }
};
