import { officer } from "./../../../public/data/user.json";

export default {
  name: "loginSection",
  data() {
    return {
      userData: [],
      password: '',
      userName: null,

      alert: false,
      messageToDisplay: "",
      alertColor: "#E2001A",
    }
  },

  computed: {
    decrypted: function () {
      return (this.encrypted ? CryptoJS.AES.decrypt(this.encrypted.str, this.decryptsecret).toString(CryptoJS.enc.Latin1) : 'fred');
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.userData = officer
      console.log(this.userData);
    },
    checkForm(e) {
      e.preventDefault()
      var user = this.userData.find(element => {
        if ((this.userName == element.userName) && (this.password == element.password)) {
          return element
        } else {
          return false
        }
      });
      console.log(user);
      if (user) {
        this.messageToDisplay = "أهلا بك  "
        this.alert = true
        this.alertColor = "#289672"
        localStorage.setItem("onsr-U", this.userName);
        this.$router.go()
      } else {
        this.messageToDisplay = "خطأ أثناء تسجيل الدخول  "
        this.alert = true
        this.alertColor = "#E2001A"
      }

    },

  },

};
