<template>
  <v-app id="inspire">
         <v-main class="body-background">
            <router-view />
         </v-main>
  </v-app>
  <!-- </v-app> -->      
</template>

<style>
.body-background {
    background-image: url('./assets/bg.png');
    /* background-size: cover; */
    background-position: center center;
    min-height: 100vh;
    width: 100vw;
    background-repeat: repeat;
}
</style>

<script>
export default {
  name: "App",
};
</script>
