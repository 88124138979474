export default {
  name: "sidebarSection",
  data() {
    return {
      drawer: true,
      items: [
        {
          title: " الداشبورد",
          subtitle: "رقم الحوادث القديمة والجديدة",
          icon: "mdi-view-dashboard",
          link: "/",
        },
        {
          title: "قائمة الحوادث القديمة",
          subtitle: " قائمة الحوادث القديمة",
          icon: "mdi-table-account",
          link: "/table",
        },
        {
          title: "قائمة الحوادث الجديدة",
          subtitle: "قائمة الحوادث الجديدة",
          icon: "mdi-table-account",
          link: "/table-accident",
        },
        {
          title: "الإعدادات البرنامج",
          subtitle: "إعدادات البرنامج",
          icon: "mdi-cog",
          link: "/settings-dashboard",
        },
        {
          title: "الإعدادات المستعمل",
          subtitle: "إعدادات المستعمل",
          icon: "mdi-cog",
          link: "/settings",
        },
      ],
    };
  },
  created() {
  },
  methods: {
    signout() {
      localStorage.removeItem('onsr-U')
      this.$router.go()
    }
  },
  computed: {
  },
};
