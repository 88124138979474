/* eslint-disable prettier/prettier */
import axios from "axios";

import { accidents } from "./../../../public/data/auth.json";


export default {
    name: 'tableAccidentSection',
    data() {
        return {
            //notification (send data)
            alert: false,
            messageToDisplay: "",
            alertColor: "#E2001A",

            expanded: [],
            singleExpand: false,
            dialog: false,
            dialogDelete: false,
            dialogPersonne: false,
            dialogMoyen: false,
            search: '',
            headers: [

                { text: 'رقم المحضر', value: 'recordNumber' },
                { text: 'تاريخ الحادث', value: 'accidentDate' },
                {
                text: 'الولاية',
                align: 'start',
                sortable: false,
                value: 'state',
            },
            { text: 'المعتمدية', value: 'delegation' },
            { text: 'المنطقة البلدية', value: 'municipality' },
            { text: 'خط الطول', value: 'long' },
            { text: 'خط العرض', value: 'lat' },
            { text: 'نوع العمران', value: 'urbanType' },
            { text: 'نوع الطريق', value: 'roadType' },
            { text: 'رقم الطريق', value: 'roadNumber' },
            { text: 'الأمتار', value: 'meters' },
            { text: 'إسم الشارع و النهج', value: 'kilometricPoint' },
            { text: 'النقطة الدالة', value: 'point' },
            { text: 'وصف الطريق', value: 'roadDescription' },
            { text: 'نظام المرور', value: 'trafficSystem' },
            { text: 'إشارات و علامات الطريق', value: 'roadSigns' },
            { text: 'سطح الطريق', value: 'roadSurface' },
            { text: 'العوامل الطبيعية', value: 'naturalFactors' },
            { text: 'مشاركة طرف و وسيلة', value: 'participation_personne_moyen' },
            { text: 'الإضاءة', value: 'lightness' },
            { text: 'خاصيات الطريق', value: 'roadSpecifications' },
            { text: 'حالة الطريق', value: 'roadState' },
                { text: 'السيارة الرابضة', value: 'parkedCars' },
                { text: '', value: 'actions' },
            ],

            headersUsersVehicle: [{
                text: 'الوسيلة',
                align: 'start',
                sortable: false,
                value: 'vehicle',
                class: 'color-white'
            }, {
                text: 'الطرف',
                align: 'start',
                sortable: false,
                value: 'users',
                class: 'color-white'
            },],


            headersDetails: [
                { text: 'المصدر', value: 'source' },
                { text: 'العدد', value: 'number' },
                { text: 'التاريخ', value: 'date' },
                { text: 'تاريخ الإعلام عن الحادث', value: 'announcementDate' },
                { text: 'توقيت الحادث', value: 'accidentTime' },
                { text: 'مجموع القتلى', value: 'deathsTotal' },
                { text: 'القتلى على عين المكان', value: 'deathsOnPlace' },
                { text: 'القتلى في ظرف يوم', value: 'deathsInDay' },
                { text: 'القتلى في ظرف أسبوع', value: 'deathsInWeek' },
                { text: 'القتلى من أسبوع إلى 30 يوم', value: 'deathsFromWeekto30Days' },
                { text: 'مجموع الجرحى', value: 'injuredTotal' },
                { text: 'جروح خطيرة', value: 'seriousInjuries' },
                { text: 'جروح بسيطة', value: 'simpleInjuries' },
                { text: 'عدد وسائل النقل', value: 'totalVehicule' },
                { text: 'عدد السواق', value: 'totalDrivers' },
                { text: 'عدد المرافقين', value: 'totalDrivers' },
                { text: 'عدد المترجلين', value: 'totalWalkers' },
                { text: 'طريقة المناورة', value: 'manouvreType' },
                { text: 'سبب الحادث', value: 'accidentReason' },
            ],

            accidents: [],
            voitures: [],
            route: [],
            personnes: [],
            moyens: [],
            editedIndex: -1,
            editedItemUsers: {},
            editedItemVehicle: {},

            indexVehicule: {},
            indexPerson: 0
        }
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'المعطيات ' : 'تغيير المعطيات'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        // dialogPersonne(val) {
        //     val || this.closePersonne()
        // },
        dialogMoyen(val) {
            val || this.closeMoyen()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.route = accidents
        },
        getFile() {
            var dataToAdd = []
            axios
                .get("http://localhost:8900/api/getFile")
                .then((response) => {
                    console.log("response", response)
                    response.data.forEach((element,i) => {
                        var vehicles = []
                        element.vehicules.forEach(vehicule => {
                            var persons = []
                            element.parts.forEach(person => {
                                if (vehicule.vehiculeNumber == person.vehiculeNumber) {
                                    persons.push(person)
                                }
                            });
                            var vehiculeData = {
                                //index:6,
                                vehiculeNumber: vehicule.vehiculeNumber,
                                vehiculeMatricule: vehicule.vehiculeMatricule,
                                vehiculeType: vehicule.vehiculeType,
                                vehiculeNationality: vehicule.vehiculeNationality,
                                propertyType: vehicule.propertyType,
                                ministry: vehicule.ministry,
                                panne: vehicule.panne,
                                technicalVisit: vehicule.technicalVisit,
                                technicalVisitDate: vehicule.technicalVisitDate,
                                firstRide: vehicule.firstRide,
                                assurance: vehicule.assurance,
                                assuranceDate: vehicule.assuranceDate,
                                assuranceCompany: vehicule.assuranceCompany,
                                vehiculeState: vehicule.vehiculeState,
                                driverViolation: vehicule.driverViolation,
                                person: persons
                            }
                            vehicles.push(vehiculeData)
                        });

                        // element.parts.forEach(person => {
                        //     if (person.vehiculeNumber == 0) {
                        //         var vehiculeData = {
                        //             // index
                        //             vehiculeMatricule: "-",
                        //             person: person
                        //         }
                        //         vehicles.push(vehiculeData)
                        //     }

                        // });

                        var newData = {
                            id: accidents[accidents.length-1].id+ i +1,
                            dateCreation: element.dateCreation,
                            id: element.id,
                            state: element.state,
                            delegation: element.delegation,
                            municipality: element.municipality,
                            long: element.long,
                            lat: element.lat,
                            urbanType: element.urbanType,
                            roadType: element.roadType,
                            roadNumber: element.roadNumber,
                            meters: element.meters,
                            kilometricPoint: element.kilometricPoint,
                            streetName: element.streetName,
                            point: element.point,
                            streetWidth: element.streetWidth,
                            roadDescription: element.roadDescription,
                            trafficSystem: element.trafficSystem,
                            roadSigns: element.roadSigns,
                            roadSurface: element.roadSurface,
                            naturalFactors: element.naturalFactors,
                            lightness: element.lightness,
                            roadSpecifications: element.roadSpecifications,
                            roadState: element.roadState,
                            parkedCars: element.parkedCars,
                            accidents: [
                                {
                                    source: element.source,
                                    number: element.number,
                                    date: element.date,
                                    telegramNumber: element.telegramNumber,
                                    recordNumber: element.recordNumber,
                                    announcementDate: element.announcementDate,
                                    accidentDate: element.accidentDate,
                                    accidentTime: element.accidentTime,
                                    deathsTotal: element.deathsTotal,
                                    deathsOnPlace: element.deathsOnPlace,
                                    deathsInDay: element.deathsInDay,
                                    deathsInWeek: element.deathsInWeek,
                                    deathsFromWeekto30Days: element.deathsFromWeekto30Days,
                                    injuredTotal: element.injuredTotal,
                                    seriousInjuries: element.seriousInjuries,
                                    simpleInjuries: element.simpleInjuries,
                                    totalVehicule: element.totalVehicule,
                                    totalParts: element.totalParts,
                                    totalDrivers: element.totalDrivers,
                                    totalCompany: element.totalCompany,
                                    totalWalkers: element.totalWalkers,
                                    manouvreType: element.manouvreType,
                                    accidentFigure: element.accidentFigure,
                                    accidentReason: element.accidentReason,
                                    notes: element.notes,
                                }
                            ],
                            vehicle: vehicles,
                        }
                        dataToAdd.push(newData)
                    });
                    this.messageToDisplay = "تم الارسا "
                    this.alert = true
                    this.alertColor = "#289672"



                    dataToAdd.forEach(item => {
                        accidents.push(item)
                    });
                    // /// new lines
                    console.log("accidents", accidents)
                    axios
                        .put("http://localhost:8900/api/data",

                        { "accidents": accidents }  
                        )

                        .then((response) => {
                            this.messageToDisplay = "تم الارسا "
                            this.alert = true
                            this.alertColor = "#289672"
                            this.$router.go()
                        })
                        .catch((error) => {
                            console.error("There was an error!", error);
                            this.messageToDisplay = "خطأ عند الإرسال"
                            this.alert = true
                            this.alertColor = "#E2001A"
                        })
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                    this.messageToDisplay = "خطأ عند الإرسال"
                    this.alert = true
                    this.alertColor = "#E2001A"
                })


        },
        //send data to server ( onsr ) using axios
        sendData(item) {
            console.log(item, this.messageToDisplay, this.alert, this.route)
            // axios
            //     .put("http://localhost:8900/api/data", { "accidents": this.route })
            //     .then((response) => {
            //         console.log(response)
            //         this.messageToDisplay = "تم الارسا "
            //         this.alert = true
            //         this.alertColor = "#289672"
            //     })
            //     .catch((error) => {
            //         console.error("There was an error!", error);
            //         this.messageToDisplay = "خطأ عند الإرسال"
            //         this.alert = true
            //         this.alertColor = "#E2001A"
            //     })
        },

        editPerson(item, index, vehicule) {
            this.indexVehicule = vehicule
            this.indexPerson = index
            this.editedItemUsers = item
            this.dialogPersonne = true
            console.log("index", this.indexVehicule, this.indexPerson, this.editedItemUsers.idRow, this.dialogPersonne);
        },

        savePerson() {
            this.dialogPersonne = false
            console.log(this.indexVehicule, this.indexPerson);
            // console.log(accidents[]);
            console.log("accident", this.editedItemUsers, this.route);
            this.route[this.editedItemUsers.idRow].vehicle[this.indexVehicule].person[this.indexPerson] = this.editedItemUsers
            axios
                .put("http://localhost:8900/api/data", { "accidents": this.route })
                .then((response) => {
                    console.log(response)
                    this.messageToDisplay = "تم الارسا "
                    this.alert = true
                    this.alertColor = "#289672"
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                    this.messageToDisplay = "خطأ عند الإرسال"
                    this.alert = true
                    this.alertColor = "#E2001A"
                })
        },

        editVehicle(item) {
            console.log(item)
            this.dialogMoyen = true
            this.editedItemVehicle = item
        },

    },
}