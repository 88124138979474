export default {
  name: "settingSection",
  data() {
    return {
      firstName: 'محمد',
      lastName: 'المستخدم',
      email: "test@email.com"
    }
  },
  created() {
  },
  methods: {
  },
  computed: {
  },
};
