import settingDashboardSection from "../../components/settingDashboardSection/index.vue";

export default {
  name: "settingPage",
  components: {
    settingDashboardSection
  },
  methods: {
  }
};
