import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import { accidents } from "./../../../public/data/auth.json";
import { oldAccidents } from "./../../../public/data/dataOld.json";

export default {
  name: "dashboardSection",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data: () => ({
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    center: [35.1193089, 8.1757156],
    zoom: 7,
    accidentsOld: [],
    accidentsNew: []
  }),
  mounted() {
  },
  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.accidentsOld = oldAccidents
      this.accidentsNew = accidents
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
      console.log(this.markers)
    },
    centerUpdated(center) {
      this.center = center;
    }
  },
  computed: {
  },
};
