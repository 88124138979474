/* eslint-disable prettier/prettier */
import { accidents } from "../../data/auth.json";
import { oldAccidents } from "../../data/dataOld.json";

export default {
  name: "dashboardSection",
  data: () => ({
    oldDate: null,
    newDate: null,
    newData: 0,
    oldData: 0,
    allData: 0,
    labels: [
      'الأحد',
      'السبت',
      'الجمعة',
      'الخميس',
      'الأربعاء',
      'الثلاثاء',
      'الأثنين',
    ],
    valueOld: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    valueNew: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    valueAll: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  }),
  created() {
    this.newData = accidents.length
    this.oldData = oldAccidents.length
    this.allData = accidents.length + oldAccidents.length
    this.valueOld = [this.oldData / 7, this.oldData / 6, this.oldData / 5, this.oldData / 4, this.oldData / 3, this.oldData / 2, this.oldData]
    this.valueNew = [this.newData / 7, this.newData / 6, this.newData / 5, this.newData / 4, this.newData / 3, this.newData / 2, this.newData]
    this.valueAll = [this.allData / 7, this.allData / 6, this.allData / 5, this.allData / 4, this.allData / 3, this.allData / 2, this.allData]
    this.oldDate = new Date(oldAccidents[oldAccidents.length - 1].dateCreation).toLocaleString()
    this.newDate = new Date(accidents[accidents.length - 1].dateCreation).toLocaleString()
  },
  methods: {
  },
  computed: {
  },
};
