import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../views/layout/index.vue";
import homePage from "../views/homePage/index.vue";
import tablePage from "../views/tablePage/index.vue";
import loginPage from "../views/loginPage/index.vue";
import settingPage from "../views/settingPage/index.vue";
import settingDashboardPage from "../views/settingDashboardPage/index.vue";
import tableAccidentPage from "../views/tableAccidentPage/index.vue";


Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: layout,
    children: [{
        path: "/",
        name: "Home",
        component: homePage,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem("onsr-U")) {
                next();
            } else {
                window.location.replace('/login');
            }
        },
    },
    {
        path: "/table",
        name: "Table_accident",
        component: tablePage,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem("onsr-U")) {
                next();
            } else {
                window.location.replace('/login');
            }
        },
    },
    {
        path: "/table-accident",
        name: "Table-accident",
        component: tableAccidentPage,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem("onsr-U")) {
                next();
            } else {
                window.location.replace('/login');
            }
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: settingPage,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem("onsr-U")) {
                next();
            } else {
                window.location.replace('/login');
            }
        },
    },
    {
        path: "/settings-dashboard",
        name: "setting-dashboard",
        component: settingDashboardPage,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem("onsr-U")) {
                next();
            } else {
                window.location.replace('/login');
            }
        },
    },

    ],
},
{

    path: "/login",
    name: "Login",
    component: loginPage,
    beforeEnter: (to, from, next) => {
        if (!localStorage.getItem("onsr-U")) {
            next();
        } else {
            window.location.replace('/');
        }
    },
}
];

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

export default router;