import sidebarSection from "../../layouts/sidebarSection/index.vue";
// import footerSection from "../../layouts/footerSection/index.vue";

export default {
  name: "layout",
  components: {
    sidebarSection,
    // footerSection
  }
};
