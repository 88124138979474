/* eslint-disable prettier/prettier */
import axios from "axios";

import { oldAccidents } from "./../../../public/data/dataOld.json";
export default {
    name: 'tableAccidentSection',
    data() {
        return {
            //notification (send data)
            alert: false,
            messageToDisplay: "",
            alertColor: "#E2001A",
            expanded: [],
            singleExpand: false,
            dialog: false,
            dialogDelete: false,
            dialogPersonne: false,
            dialogMoyen: false,
            search: '',
            headers: [{ text: 'تاريخ الحادث', value: 'accidentDate', width:'130px'},
                { text: 'رقم المحضر', value: 'recordNumber' },
                {
                text: 'الولاية',
                align: 'start',
                sortable: false,
                value: 'state',
            },
            { text: 'المعتمدية', value: 'delegation' },
            { text: 'المنطقة البلدية', value: 'municipality' },
            { text: 'خط الطول', value: 'long' },
            { text: 'خط العرض', value: 'lat' },
            { text: 'نوع العمران', value: 'urbanType' },
            { text: 'نوع الطريق', value: 'roadType' },
            { text: 'رقم الطريق', value: 'roadNumber' },
            { text: 'الأمتار', value: 'meters' },
            { text: 'إسم الشارع و النهج', value: 'kilometricPoint' },
            { text: 'النقطة الدالة', value: 'point' },
            { text: 'وصف الطريق', value: 'roadDescription' },
            { text: 'نظام المرور', value: 'trafficSystem' },
            { text: 'إشارات و علامات الطريق', value: 'roadSigns' },
            { text: 'سطح الطريق', value: 'roadSurface' },
            { text: 'العوامل الطبيعية', value: 'naturalFactors' },
            { text: 'مشاركة طرف و وسيلة', value: 'participation_personne_moyen' },
            { text: 'الإضاءة', value: 'lightness' },
            { text: 'خاصيات الطريق', value: 'roadSpecifications' },
            { text: 'حالة الطريق', value: 'roadState' },
            { text: 'السيارة الرابضة', value: 'parkedCars' },
            ],

            headersUsersVehicle: [{
                text: 'الوسيلة',
                align: 'start',
                sortable: false,
                value: 'vehicle',
                class: 'color-white'
            }, {
                text: 'الطرف',
                align: 'start',
                sortable: false,
                value: 'users',
                class: 'color-white'
            },],


            headersDetails: [
                { text: 'المصدر', value: 'source' },
                { text: 'العدد', value: 'number' },
                { text: 'التاريخ', value: 'date' },
                { text: 'تاريخ الإعلام عن الحادث', value: 'announcementDate' },
                { text: 'تاريخ الحادث', value: 'accidentDate' },
                { text: 'توقيت الحادث', value: 'accidentTime' },
                { text: 'مجموع القتلى', value: 'deathsTotal' },
                { text: 'القتلى على عين المكان', value: 'deathsOnPlace' },
                { text: 'القتلى في ظرف يوم', value: 'deathsInDay' },
                { text: 'القتلى في ظرف أسبوع', value: 'deathsInWeek' },
                { text: 'القتلى من أسبوع إلى 30 يوم', value: 'deathsFromWeekto30Days' },
                { text: 'مجموع الجرحى', value: 'injuredTotal' },
                { text: 'جروح خطيرة', value: 'seriousInjuries' },
                { text: 'جروح بسيطة', value: 'simpleInjuries' },
                { text: 'عدد وسائل النقل', value: 'totalVehicule' },
                { text: 'عدد السواق', value: 'totalDrivers' },
                { text: 'عدد المرافقين', value: 'totalDrivers' },
                { text: 'عدد المترجلين', value: 'totalWalkers' },
                { text: 'طريقة المناورة', value: 'manouvreType' },
                { text: 'سبب الحادث', value: 'accidentReason' },
            ],

            accidents: [],
            voitures: [],
            route: [],
            personnes: [],
            moyens: [],
            editedIndex: -1,
            editedItemUsers: {},
            editedItemVehicle: {},
        }
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'المعطيات ' : 'تغيير المعطيات'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogPersonne(val) {
            val || this.closePersonne()
        },
        dialogMoyen(val) {
            val || this.closeMoyen()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.route = oldAccidents
        },
        editPerson(item) {
            console.log(item)
            this.editedItemUsers = item
            this.dialogPersonne = true
        },
        editVehicle(item) {
            console.log(item)
            this.dialogMoyen = true
            this.editedItemVehicle = item
        },
    },

}