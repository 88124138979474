
/* eslint-disable prettier/prettier */
import axios from "axios";

import { field } from "./../../../public/data/field.json";
export default {
  name: "settingDashboardSection",
  data() {
    return {
      switch1: true,
      route: [],
      //notification (send data)
      alert: false,
      messageToDisplay: "",
      alertColor: "#E2001A",
      singleExpand: false,
      search: '',
      headers: [{
        text: 'عنوان المجال',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: ' نوع (إلزامي أم لا)', value: 'required', sortable: false, },
      { text: ' مرئية أو خفية', value: 'visible', sortable: false, },
      ],
    }
  },

  computed: {

  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.route = field
      console.log(this.route);
    },

    //send data to server ( onsr ) using axios
    sendData(item) {
      console.log(item, this.messageToDisplay, this.alert)
      // axios
      //     .post(
      //         "link", item
      //     )
      //     .then((response) => {
      //         console.log(response)
      //         this.messageToDisplay = "  تم الارسا "
      //         this.alert = true
      //         this.alertColor = "#289672"
      //     })
      //     .catch((error) => {
      //         console.error("There was an error!", error);
      //         this.messageToDisplay = "خطأ عند الإرسال"
      //         this.alert = true
      //         this.alertColor = "#E2001A"
      //     })
    },
    sendFieldOption() {
      // var field = []
      // var dataToSend = field.push(this.route)
      axios
        .post("http://localhost:8900/api/json-required", { "field": this.route })
        .then((response) => {
          console.log(response)
          this.messageToDisplay = "  تم الارسا "
          this.alert = true
          this.alertColor = "#289672"
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.messageToDisplay = "خطأ عند الإرسال"
          this.alert = true
          this.alertColor = "#E2001A"
        })
    },

    savePerson() {
      console.log(this.indexVehicule, this.indexPerson);
      // console.log(accidents[]);
      console.log("accident", this.editedItemUsers);
    },

    editVehicle(item) {
      console.log(item)
      this.editedItemVehicle = item
    },

  },
}