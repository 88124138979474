import settingSection from "../../components/settingSection/index.vue";

export default {
  name: "settingPage",
  components: {
    settingSection
  },
  methods: {
  }
};
