var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":_vm.alertColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"indigo","text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" اغلاق ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.messageToDisplay)+" ")]),_c('div',{staticClass:"element-position"}),_c('v-data-table',{staticClass:"elevation-1 v-border-table mt-10",attrs:{"headers":_vm.headers,"items":_vm.route,"single-expand":_vm.singleExpand,"item-key":"id","footer-props":{
      'items-per-page-text':'معطيات كل صفحة','pageText': '{0}-{1} من {2}'
 }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
 var headers = ref.headers;
 var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.required",fn:function(ref){
 var headers = ref.headers;
 var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.required),callback:function ($$v) {_vm.$set(item, "required", $$v)},expression:"item.required"}})]}},{key:"item.visible",fn:function( ref){
 var headers = ref.headers;
 var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }